import NextLink from 'next/link';
import getLinkAttr from './useLinkAttr';
import { LinkSwitchableMixin } from '@/src/Utils/magnolia';
import formatStyle from '@/src/Utils/formatStyle';

interface LinkPropsInterface extends LinkSwitchableMixin {
  openLinkInNewBrowserTab?: boolean;
  title: string;
  styles: string;
}

const Link = (props: LinkPropsInterface) => {
  const [linkAttr] = getLinkAttr(props);
  const { title, styles } = props;
  return (
    // <NextLink href={'/'} {...linkAttr}>
    <span style={formatStyle(styles)} className="link">
      {title}
    </span>
    // </NextLink>
  );
};

export default Link;
