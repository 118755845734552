import { Props } from '../templates/Layouts/DefaultLayout';
import Head from 'next/head';

function MetaTags(props: Props) {
  const {
    title,
    metaTitle,
    metaDescription,
    robots,
    metaRobots,
    socialMediaTitle,
    socialMediaDescription,
    socialMediaImage,
  } = props;

  return (
    <Head>
      <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />

      <meta name="theme-color" content="#000000" />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        media="all"
        sizes="16x16 32x32 96x96"
        href="/favicon.ico"
      />

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />

      <meta charSet="utf-8" />
      <title>{metaTitle ?? title}</title>
      {metaDescription && <meta name="description" content={metaDescription} />}
      {/* TODO: <link rel="canonical" .../>*/}
      {robots && robots.length > 0 && <meta name="robots" content={robots.join(',')} />}
      {metaRobots && metaRobots.length > 0 && <meta name="robots" content={metaRobots.join(',')} />}
      {socialMediaTitle && <meta property="og:title" content={socialMediaTitle} />}
      {socialMediaDescription && (
        <meta property="og:description" content={socialMediaDescription} />
      )}
      {socialMediaImage && (
        <meta
          property="og:image"
          content={socialMediaImage.renditions?.['1200']?.link ?? socialMediaImage['@link']}
        />
      )}
      {socialMediaImage &&
        (socialMediaImage.metadata.caption || socialMediaImage.metadata.title) && (
          <meta
            property="og:image:alt"
            content={socialMediaImage.metadata.caption ?? socialMediaImage.metadata.title}
          />
        )}

      <link
        rel="shortcut icon"
        type="image/x-icon"
        media="all"
        sizes="16x16 32x32 96x96"
        href="/favicon.ico"
      />

      {/* TODO: <link rel="apple-touch-icon" .../> */}
    </Head>
  );
}

export default MetaTags;
