import { Row, RowProps } from 'react-bootstrap';
import { EditableArea } from '@magnolia/react-editor';
import { MagnoliaAreaNode, MagnoliaPageNode } from '@/src/Utils/magnolia';
import stylesRow from './Row.module.scss';
import { Fragment } from 'react';
import formatStyle from '@/src/Utils/formatStyle';

interface RowInterface {
  sameHeight: boolean;
  customClass: string;
  styles: string;
  row: MagnoliaAreaNode;
  metadata: MagnoliaPageNode;
}

const RowWrapper = (props: RowInterface) => {
  const { row } = props;
  const [attr] = getRowAttr(props);
  return <Row {...attr}>{row && <EditableArea elementType={Fragment} content={row} />}</Row>;
};

export default RowWrapper;

const getRowAttr = (props: RowInterface) => {
  const { customClass, styles, sameHeight } = props;
  let attr = {} as RowProps;
  if (customClass) attr = { ...attr, className: customClass };
  if (styles) attr = { ...attr, style: formatStyle(styles) };
  if (sameHeight) {
    attr = { ...attr, className: `${attr.className || ''} ${stylesRow.display__equal}` };
  }
  return [attr];
};
