import { CSSProperties } from 'react';

const formatStyle = (style: string): CSSProperties => {
  let styles: CSSProperties = {};
  if (style && style.length > 0) {
    const splitteStyles = style.replaceAll(' ', '').split(';');
    splitteStyles.map(uniqStyle => {
      const splittedUniqStyle = uniqStyle.split(':');
      const formattedStyle: { [a: string]: string } = {};
      if (splittedUniqStyle[0] && splittedUniqStyle[1]) {
        formattedStyle[splittedUniqStyle[0]] = splittedUniqStyle[1];
        const objectStyle = formattedStyle as CSSProperties;
        styles = { ...styles, ...objectStyle };
      }
    });
  }

  return styles;
};

export default formatStyle;
