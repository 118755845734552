import { EditableArea } from '@magnolia/react-editor';
import { Props } from '../Layouts/DefaultLayout';
import { MagnoliaAreaNode } from '../../Utils/magnolia';

export interface StaticPageProps extends Props {
  header: MagnoliaAreaNode;
  footer: MagnoliaAreaNode;
  body: MagnoliaAreaNode;
}

const StaticPage = (props: StaticPageProps) => {
  const { header, footer, body } = props;
  return (
    <>
      {header && <EditableArea content={header} />}
      <div className="pass__center">
        <div className="max-width">
          <div className="ml-2 mr-2 mr-lg-5 ml-lg-5 mt-4 mt-md-5">
            {body && <EditableArea content={body} />}
          </div>
        </div>
      </div>
      {footer && <EditableArea content={footer} />}
    </>
  );
};

export default StaticPage;
