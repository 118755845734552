import Accordion from 'react-bootstrap/Accordion';
// import AccordionElement from './AccordionElement';
import { MagnoliaAreaNode, MagnoliaPageNode } from '@/src/Utils/magnolia';
import { EditableArea } from '@magnolia/react-editor';

interface AccordionCustomProps {
  accordion: MagnoliaAreaNode;
  metadata: MagnoliaPageNode;
  speed: number;
  defaultActiveKey: number;
}

const AccordionCustom = (props: AccordionCustomProps) => {
  const { accordion, defaultActiveKey } = props;
  return (
    <Accordion defaultActiveKey={defaultActiveKey ? defaultActiveKey.toString() : ''}>
      {accordion && <EditableArea content={accordion} />}
    </Accordion>
  );
};
export default AccordionCustom;
