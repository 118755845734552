import { EditablePage } from '@magnolia/react-editor';
import { GetServerSidePropsContext } from 'next/types';
import { ReactElement } from 'react';
import { EditorContextHelper } from '@magnolia/template-annotations';
import { IMagnoliaContext } from '@magnolia/template-annotations/src/service/GetMagnoliaContext';
import deepmerge from 'deepmerge';
import {
  apiFetch,
  apis,
  config,
  NODES_PATH,
  Props,
  PUBLIC_CONFIG_NODE_PATH,
  rootNodePath,
  StaticPageLayoutProps,
} from '@/src/Utils/magnolia';
import { locales } from '@/src/Utils/i18n';
import DefaultPageLayout from '@/src/templates/Layouts/DefaultLayout';
import useConfig from '@/src/Utils/config';
import { apiCMS } from '@/src/Utils/api';
import { CMSApiBreadCrumbResponse } from '@/src/lib/api/generated';
import { ConfigModel } from '@/src/lib/services';
import { MGNLGlobalPropsContext } from '@/src/Utils/MgnlGlobalContext';
import { ThemeContext } from '@/src/Utils/ThemeContext';
import projectTheme from '@/src/Styles/theme';

const CmsPage = (props: Props) => {
  const { page = {} as StaticPageLayoutProps, templateAnnotations = {}, magnoliaContext } = props;
  const MappedLayout = config.layoutMappings[page?.['mgnl:template']] ?? DefaultPageLayout;
  return (
    <div className={magnoliaContext.isMagnoliaEdit ? 'disable-a-pointer-events' : ''}>
      <MGNLGlobalPropsContext.Provider value={props}>
        <ThemeContext.Provider value={projectTheme}>
          <MappedLayout {...page}>
            <EditablePage
              content={page}
              config={config}
              templateAnnotations={templateAnnotations}
            />
          </MappedLayout>
        </ThemeContext.Provider>
      </MGNLGlobalPropsContext.Provider>
    </div>
  );
};

CmsPage.getLayout = function getLayout(page: ReactElement) {
  const MappedLayout = config.layoutMappings[page.props.page['mgnl:template']] ?? DefaultPageLayout;
  console.log('====================================');
  console.log('MappedLayout MappedLayout', MappedLayout);
  console.log('====================================');
  return <MappedLayout {...page.props.page}>{page}</MappedLayout>;
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const magnoliaContext: IMagnoliaContext = EditorContextHelper.getMagnoliaContext(
    '/' + (context.locale || 'fr') + context.resolvedUrl,
    rootNodePath,
    locales
  );

  // console.log(
  //   'rootNodePath rootNodePath',
  //   rootNodePath,
  //   ' context.resolvedUrl',
  //   context.resolvedUrl,
  //   'context.locale',
  //   context.locale,
  //   'BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB',
  //   magnoliaContext
  // );

  const promisesRes = [];

  // Fetching page content
  const pagesRes = apiFetch(apis.pages + magnoliaContext.nodePath + magnoliaContext.search);
  promisesRes.push(pagesRes);

  // // Fetching config
  const configRes = apiFetch(apis.config + PUBLIC_CONFIG_NODE_PATH);
  promisesRes.push(configRes);

  // // Fetching header navigation
  const headerNavRes = apiFetch(apis.pagenav + rootNodePath + NODES_PATH);
  promisesRes.push(headerNavRes);

  // inherit of header/footer areas
  if (magnoliaContext.nodePath !== rootNodePath) {
    const homepageRes = apiFetch(apis.pages + rootNodePath + magnoliaContext.search);
    promisesRes.push(homepageRes);
  }

  const [pageDataNormal, configData, headerNavData, homepageData] = await Promise.all(
    promisesRes
  ).then(async res => {
    return await Promise.all(res.map(async re => await re.json()));
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const config = deepmerge(useConfig(), configData) as ConfigModel;

  // Handling unknown URLs
  let pageDataNotFound;
  if (pageDataNormal?.error) {
    const errorPageCMSName = config.errorPageCMSName;
    console.error('Requested route not available', pageDataNormal?.error);
    console.log('MAGNOLIA_HOST', process.env.MAGNOLIA_HOST);

    let pageResNotFound;
    if (errorPageCMSName) {
      pageResNotFound = apiFetch(
        apis.pages + rootNodePath + '/' + errorPageCMSName + '/' + magnoliaContext.search
      );
      pageDataNotFound = await (await pageResNotFound).json();
    } else {
      console.error('errorPageCMSName undefined.');
    }
  }

  const pageData = pageDataNotFound || pageDataNormal;

  if (homepageData && magnoliaContext.nodePath !== rootNodePath) {
    if (!pageData?.header || pageData.header['@nodes'].length === 0) {
      if (homepageData?.header) pageData.header = homepageData?.header;
    }
    if (!pageData?.footer || pageData.footer['@nodes'].length === 0) {
      if (homepageData?.footer) pageData.footer = homepageData?.footer;
    }
  }

  // let breadCrumbResponse = [] as CMSApiBreadCrumbResponse['nodes'];
  // if (pageData?.breadCrumbs) {
  //   const response = await apiCMS.getBreadCrumbNodes(pageData['@id']);
  //   breadCrumbResponse = [...(response.nodes || []).reverse()];
  // }
  // console.log('====================================');
  // console.log('magnoliaContext magnoliaContext', magnoliaContext);
  // console.log('====================================');
  const props: Props = {
    breadCrumb: [],
    // breadCrumb: breadCrumbResponse,
    magnoliaContext,
    headerNav: headerNavData || [],
    rootNodePath,
    page: pageData,
    config: config,
    magnoliaUrl: process.env.MAGNOLIA_HOST,
  };

  // console.log('====================================');
  // console.log('props props', props);
  // console.log('====================================');

  // Fetch template annotations only inside Magnolia WYSIWYG
  if (magnoliaContext.isMagnolia) {
    console.log(
      'apis.templateAnnotations + magnoliaContext.nodePath',
      apis.templateAnnotations + magnoliaContext.nodePath
    );

    const templateAnnotations = await apiFetch(apis.templateAnnotations + magnoliaContext.nodePath);
    const templateAnnotation = await templateAnnotations.json();
    console.error('templateAnnotation templateAnnotation.', templateAnnotation);
    props.templateAnnotations = templateAnnotation;
  }
  // @ts-ignore
  global.mgnlInPageEditor = magnoliaContext.isMagnolia;
  return {
    props,
  };
}

export default CmsPage;
