// import styled, { StyledInterface } from 'styled-components';
import deepmerge from 'deepmerge';
import { StylesConfig as ReactSelectStyles } from 'react-select';
import { ThemeConfig as ReactSelectThemeConfig } from 'react-select/dist/declarations/src/theme';
import CustomThemeType from './Theme/CustomTheme';

const createTheme = (customTheme: CustomThemeType): CustomThemeType => {
  const scssVars = customTheme.bootstrap;

  const reactSelectTheme: ReactSelectThemeConfig = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      ...{
        primary: scssVars.inputFocusBorderColor,
        neutral20: scssVars.inputBorderColor,
      },
    },
  });

  const reactSelectStyles: ReactSelectStyles = {
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 0.75rem',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: scssVars.inputColor,
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      cursor: 'pointer',
      color: isSelected ? scssVars.white : scssVars.inputColor,
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: isSelected ? scssVars.gray600 : isFocused ? scssVars.gray200 : undefined,
    }),
    control: (provided, state) => ({
      ...provided,
      borderWidth: scssVars.borderWidth,
      boxShadow: state.isFocused ? scssVars.inputFocusBoxShadow : 'none',
      borderColor: scssVars.inputBorderColor,
      minHeight: 'auto',
    }),
    container: (provided) => ({
      ...provided,
      borderWidth: scssVars.borderWidth,
      boxShadow: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: scssVars.primary,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 1100,
    }),
  };

  const baseTheme: CustomThemeType = {
    bootstrap: scssVars,
    reactSelectTheme,
    reactSelectStyles,
    placeholderImages: {
      // logo: '',
    },
    images: {
      // logo: '',
    },
  };

  return { ...deepmerge(baseTheme, customTheme) };
};

export default createTheme;
