import { MagnoliaAreaNode, MagnoliaComponentNode } from '@/src/Utils/magnolia';
import { EditableArea, EditableComponent } from '@magnolia/react-editor';
import { ElementType } from 'react';

type LinkList = 'bullet' | 'ordered' | 'inline';

interface LinkListInterface {
  subTitle: string;
  listStyle: LinkList;
  link?: MagnoliaAreaNode & { [a: string]: MagnoliaAreaNode };
}

const LinkList = (props: LinkListInterface) => {
  const { link, subTitle, listStyle = 'bullet' } = props;
  const ulClassName = listStyle === 'inline' ? 'list-inline' : ``;
  const Tag = tags[listStyle];
  return (
    <div className={'link-list'}>
      {subTitle ? <h4>{subTitle}</h4> : null}
      <Tag className={ulClassName}>
        {link ? <EditableArea content={link} customView={LinkListElement} /> : null}
      </Tag>
    </div>
  );
};

const LinkListElement = ({
  content,
}: {
  content: MagnoliaComponentNode & { [a: string]: MagnoliaComponentNode };
}) => {
  return (
    <>
      {content['@nodes'].map(node => {
        return (
          <li key={content[node]['@id']}>
            <EditableComponent content={content[node]} />
          </li>
        );
      })}
    </>
  );
};

export default LinkList;

const tags: { [a in LinkList]: ElementType } = {
  bullet: 'ul',
  ordered: 'ol',
  inline: 'ul',
};
