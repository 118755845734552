import { LinkSwitchableMixin, MagnoliaAreaNode } from '@/src/Utils/magnolia';
import { useMGNLGlobalPropsContext } from '@/src/Utils/MgnlGlobalContext';
import formatStyle from '@/src/Utils/formatStyle';
import { EditableArea } from '@magnolia/react-editor';
import { Fragment } from 'react';

interface ImagePropsInterface {
  imageAltText: string;
  imageTitle: string;
  imageCaption?: string;
  imageCredit?: string;
  keepAspectRatio: boolean;
  image: LinkSwitchableMixin['linkTypedownload'];
  styles?: string;
  imageComponent?: MagnoliaAreaNode;
}

const Image = (props: ImagePropsInterface) => {
  const {
    image,
    imageAltText,
    imageTitle,
    imageCaption,
    imageCredit,
    styles = '',
    keepAspectRatio,
    imageComponent,
  } = props;
  const mgnlCtx = useMGNLGlobalPropsContext();

  // const { metadata, renditions } = image || {};

  // const imageProps = useImageSize({
  //   metadata,
  //   renditions,
  //   height: metadata?.height,
  //   width: metadata?.width,
  // });
  const Tag = imageComponent ? 'div' : Fragment;
  const imageElement = (
    <Tag>
      <img
        // {...imageProps}
        alt={imageAltText}
        title={imageTitle}
        src={(mgnlCtx?.magnoliaUrl || '') + image?.['@link']}
        className="image__component"
        style={{
          ...formatStyle(styles),
          ...{ objectFit: keepAspectRatio ? 'contain' : undefined },
        }}
      />
      {imageComponent ? <EditableArea content={imageComponent} /> : null}
    </Tag>
  );
  return (
    <figure>
      {imageElement}
      {imageCaption ? <figcaption>{imageCaption}</figcaption> : null}
      {imageCredit ? <p>{imageCredit}</p> : null}
    </figure>
  );
};

export default Image;
