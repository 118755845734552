import DOMPurify from 'isomorphic-dompurify';
const sanitize = (innerHtml: string, withTargetAttr?: boolean) => {
  if (withTargetAttr) addTargetBlankToTagSecurely();
  return DOMPurify.sanitize(innerHtml);
};

const addTargetBlankToTagSecurely = () => {
  // handle befoe and after sanitize
};

export default sanitize;
