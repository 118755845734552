import { LinkSwitchableMixin } from '@/src/Utils/magnolia';
import { AnchorHTMLAttributes } from 'react';

const getLinkAttr = (
  props: LinkSwitchableMixin & { openLinkInNewBrowserTab?: boolean }
): [AnchorHTMLAttributes<any>] => {
  const {
    linkType,
    linkTypedownload,
    linkTypeexternal = '',
    linkTypepage,
    openLinkInNewBrowserTab,
  } = props;
  let attr: AnchorHTMLAttributes<any> = { href: '/' };

  if (linkType === 'download') {
    if (linkTypedownload?.['@link']) {
      attr = {
        ...attr,
        download: true,
        href: linkTypedownload?.['@link'],
        target: '_blank',
        rel: 'noopener noreferrer',
      };
    }
  }

  if (linkType === 'external') {
    const link =
      linkTypeexternal.indexOf('://') === -1 ? 'http://' + linkTypeexternal : linkTypeexternal;
    attr = { ...attr, href: link };
  }

  if (linkType === 'page') {
    attr = {
      ...attr,
      href: (linkTypepage?.['@path'] || '/').replace(/^(\/talent|\/home|\/labs)/, ''),
    };
  }
  if (openLinkInNewBrowserTab) {
    attr = { ...attr, target: '_blank', rel: 'noopener noreferrer' };
  }
  return [attr];
};

export default getLinkAttr;
