import { Col, ColProps } from 'react-bootstrap';
import { EditableArea } from '@magnolia/react-editor';
import { MagnoliaAreaNode, MagnoliaPageNode } from '@/src/Utils/magnolia';
import { CSSProperties } from 'react';
import formatStyle from '@/src/Utils/formatStyle';

type NumberAttr = number | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
interface ColInterface {
  col: MagnoliaAreaNode;
  metadata: MagnoliaPageNode;
  customClass: string;
  styles: string;
  xxl: NumberAttr;
  xl: NumberAttr;
  md: NumberAttr;
  sm: NumberAttr;
  xs: NumberAttr;
}

const ColWrapper = (props: ColInterface) => {
  const { col } = props;
  const [attr] = getColAttr(props);
  return <Col {...attr}>{col && <EditableArea content={col} />}</Col>;
};
export default ColWrapper;

const getColAttr = (props: ColInterface) => {
  const { customClass, styles, xxl, xl, md, sm, xs } = props;
  let attr = {} as ColProps;
  if (customClass) attr = { ...attr, className: customClass };
  if (styles) attr = { ...attr, style: formatStyle(styles) };
  if (xxl) attr = { ...attr, xxl: xxl };
  if (xl) attr = { ...attr, xl: xl };
  if (md) attr = { ...attr, md: md };
  if (sm) attr = { ...attr, sm: sm };
  if (xs) attr = { ...attr, xs: xs };
  return [attr];
};
