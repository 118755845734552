interface HeadlineProps {
  value: string;
  position: 'center' | 'right' | 'left';
  tagType: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';
}

const Headline = (props: HeadlineProps) => {
  const { value, position, tagType } = props;
  const Tag = tagType || 'h1';
  return (
    <div className="headline">
      <Tag className={`text-${position}`}>{value}</Tag>
    </div>
  );
};

export default Headline;
