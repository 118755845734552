import sanitize from '@/src/lib/services/Utils/sanitize';
import styles from './RichText.module.scss';
interface RichTextProps {
  content: string;
}
const RichText = (props: RichTextProps) => {
  const { content } = props;
  return (
    <div
      className={`${styles.richtext} richtText`}
      dangerouslySetInnerHTML={{ __html: sanitize(content, true) }}
    ></div>
  );
};
export default RichText;
