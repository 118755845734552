import formatStyle from '@/src/Utils/formatStyle';
import { MagnoliaAreaNode } from '@/src/Utils/magnolia';
import { EditableArea } from '@magnolia/react-editor';

interface WrapperProps {
  wrapper?: MagnoliaAreaNode;
  styles: string;
  customClass: string;
}

const Wrapper = (props: WrapperProps) => {
  const { customClass, styles, wrapper } = props;
  return (
    <div className={customClass} style={formatStyle(styles)}>
      {wrapper ? <EditableArea content={wrapper} /> : null}
    </div>
  );
};

export default Wrapper;
