import React from 'react';
import { MagnoliaDamNode, MagnoliaPageNode } from '../../Utils/magnolia';
import MetaTags from '../../Utils/MetaTag';

export interface Props {
  children: React.ReactNode;
  breadCrumbs: boolean;
  title: string;
  metaTitle?: string;
  metaDescription?: string;
  metaRobots?: string[];
  robots?: string[];
  socialMediaTitle?: string;
  socialMediaDescription?: string;
  socialMediaImage?: MagnoliaDamNode;
  // Magnolia metadata
  metadata: MagnoliaPageNode;
  'mgnl:template': string;
}

const DefaultPageLayout = (props: Props) => {
  return (
    <>
      <MetaTags {...props} />
      <div className="page__blank">{props.children}</div>
    </>
  );
};

export default DefaultPageLayout;
