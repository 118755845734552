import Link from 'next/link';
import { HEADER_ID, HeaderReturnProps } from './useHeader';
import { Button } from 'react-bootstrap';
import { FiMenu, FiX } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import NextImage from 'next/image';
import useImageSize from '../image/useImageSize';

const HeaderMobile = (props: HeaderReturnProps) => {
  const [open, setOpen] = useState(false);
  const {
    isSticky,
    image: { mobile },
    imageAltText,
    imageTitle,
  } = props;

  const imageProps = useImageSize({
    height: 40,
    metadata: mobile.metadata,
    renditions: mobile.renditions,
  });

  return (
    <>
      <div id={HEADER_ID} className={`header header--mobile ${isSticky ? 'header__sticky' : ''}`}>
        <div className="header__container--mobile">
          <img
            {...imageProps}
            className="header__image"
            alt={imageAltText}
            title={imageTitle}
            src={mobile?.url}
          />
          <Button style={{ lineHeight: '15px' }} onClick={() => setOpen(!open)}>
            {open ? <FiX /> : <FiMenu />}
          </Button>
        </div>
      </div>
      {open ? <HeaderDropdown {...props} /> : null}
    </>
  );
};

export default HeaderMobile;

const HeaderDropdown = (props: HeaderReturnProps) => {
  const { button, links } = props;
  useEffect(() => {
    const body = document.body;
    body.classList.add('no__scroll');
    return () => {
      body.classList.remove('no__scroll');
    };
  }, []);

  return (
    <div className="header__dropdown">
      <div>
        {links.map((link, ind) => (
          <div key={ind}>
            <Link href={'/'} {...link.attr}>
              <button {...button.attr} className="btn">
                {link.text}
              </button>
            </Link>
          </div>
        ))}
      </div>
      <Link href={'/'} {...button.buttonLinkAttr}>
        <button {...button.attr}>{button.title}</button>
      </Link>
    </div>
  );
};
