import { MagnoliaDamNode } from '@/src/Utils/magnolia';
import Image, { ImageProps } from 'next/image';

interface UseImageProps {
  height?: number;
  width?: number;
  metadata?: MagnoliaDamNode['metadata'];
  renditions?: MagnoliaDamNode['renditions'];
}
const useImageSize = (props: UseImageProps): Partial<ImageProps> => {
  const { height, metadata, width, renditions } = props;
  const ratio = (metadata?.height || 1) / (metadata?.width || 1);
  let calculatedWidth = 0;
  let calculatedHeight = 0;
  if (height) {
    calculatedWidth = height / ratio;
    calculatedHeight = height;
  }
  if (width) {
    calculatedHeight = width * ratio;
    calculatedWidth = width;
  }
  if (width && height) {
    calculatedWidth = height;
    calculatedHeight = width;
  }

  return {
    width: calculatedWidth,
    height: calculatedHeight,
  };
};

export default useImageSize;
