import Accordion from 'react-bootstrap/Accordion';
import { EditableArea } from '@magnolia/react-editor';
import styles from './Accordion.module.scss';
import { MagnoliaAreaNode, MagnoliaPageNode } from '@/src/Utils/magnolia';
import RichText from '../richText/RichText';

interface AccordionElementProps {
  metadata: MagnoliaPageNode;
  content: string;
  accordionElement: MagnoliaAreaNode;
}

const AccordionElement = (props: AccordionElementProps) => {
  const { content, accordionElement, metadata } = props;
  const index = (metadata['@index'] || 0) + 1;
  return (
    <Accordion.Item eventKey={index.toString()} className={styles.accordion_element}>
      <Accordion.Header>
        <RichText content={content} />
      </Accordion.Header>
      <Accordion.Body>
        {accordionElement && <EditableArea content={accordionElement} />}
      </Accordion.Body>
    </Accordion.Item>
  );
};
export default AccordionElement;
