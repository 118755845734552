import { ButtonPropsInterface, ButtonSample } from './Button';

const ConsentButton = (props: ButtonPropsInterface) => {
  const onClick = () => {
    console.log('====================================');
    console.log('onClick onClick onClick');
    console.log('====================================');
  };
  return <ButtonSample onClick={onClick} {...props} />;
};

export default ConsentButton;
