import Link from 'next/link';
import { HEADER_ID, HeaderReturnProps } from './useHeader';
import NextImage from 'next/image';
import useImageSize from '../image/useImageSize';

const HeaderBigScreen = (props: HeaderReturnProps) => {
  const {
    isSticky,
    button,
    image: { bigScreen },
    imageAltText,
    imageTitle,
    links,
  } = props;
  const imageProps = useImageSize({
    height: 28,
    metadata: bigScreen.metadata,
    renditions: bigScreen.renditions,
  });
  console.log('====================================');
  console.log('link.attr link.button.buttonLinkAttr button.buttonLinkAttr', button.buttonLinkAttr);
  console.log('====================================');
  return (
    <div id={HEADER_ID} className={`header ${isSticky ? 'header__sticky' : ''}`}>
      <div className="header__container">
        <img
          {...imageProps}
          className="header__image"
          alt={imageAltText}
          title={imageTitle}
          src={bigScreen?.url}
        />
        <div>
          {links.map((link, ind) => {
            return (
              <Link key={ind} href={'/'} {...link.attr}>
                <button {...button.attr} className="btn">
                  {link.text}
                </button>
              </Link>
            );
          })}
        </div>
        {/* <Link href={'/'} {...button.buttonLinkAttr}> */}
        <button {...button.attr}>{button.title}</button>
        {/* </Link> */}
      </div>
    </div>
  );
};

export default HeaderBigScreen;
