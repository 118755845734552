import { IMagnoliaContext } from '@magnolia/template-annotations/src/service/GetMagnoliaContext';

import StaticPage from '../templates/pageLayouts/StaticPage';
import StaticRawPage from '../templates/pageLayouts/StacticRawPage';
import StaticLayout from '../templates/Layouts/DefaultLayout';
import Header from '../components/header/Header';
import Headline from '../components/headline/Headline';
import RichText from '../components/richText/RichText';
import Image from '../components/image/Image';
import Footer from '../components/footer/Footer';
import Accordion from '../components/accordion/Accordion';
import AccordionElement from '../components/accordion/AccordionElement';
import FixedColumnLayout from '../components/fixedColumnLayout/FixedColumnLayout';
import Navigation from '../components/navigation/Navigation';
import Teaser from '../components/teaser/Teaser';
import Link from '../components/linkList/Link';
import LinkList from '../components/linkList/LinkList';
import Button from '../components/button/Button';
import ConsentButton from '../components/button/ConsentButton';
import Container from '../components/container/Container';
import Row from '../components/row/Row';
import Col from '../components/column/Col';

import { Props as StaticPageDefaultLayoutProps } from '../templates/Layouts/DefaultLayout';

import axios, { AxiosResponse } from 'axios';
import { CMSApiBreadCrumbResponse } from '../lib/api/generated';
import { ConfigModel } from '../lib/services';
import Wrapper from '../components/wrapper/Wrapper';

export const NODES_PATH = '/@nodes';
export const PUBLIC_CONFIG_NODE_PATH = '/fe-public-config';

export const magnoliaUrl: string = process.env.MAGNOLIA_HOST ?? '';
export const apis = {
  base: magnoliaUrl + (process.env.MAGNOLIA_API_BASE ?? '/.rest'),
  templateAnnotations:
    magnoliaUrl + (process.env.MAGNOLIA_API_TEMPLATES ?? '/.rest/template-annotations/v1'),
  pages: magnoliaUrl + (process.env.MAGNOLIA_API_PAGES ?? '/.rest/delivery/pages/v1'),
  pagenav: magnoliaUrl + (process.env.MAGNOLIA_API_NAV ?? '/.rest/delivery/pagenav/v1'),
  config: magnoliaUrl + (process.env.MAGNOLIA_API_CONFIG ?? '/.rest/delivery/config/v1'),
};
// : ReturnType<typeof axios>
export const credentials = Buffer.from(
  process.env.MAGNOLIA_AUTH_FRONTEND_USER + ':' + process.env.MAGNOLIA_AUTH_FRONTEND_PASSWORD
).toString('base64');
export function apiFetchAxios(url: string): Promise<AxiosResponse> {
  if (!process.env.MAGNOLIA_AUTH_FRONTEND_USER || !process.env.MAGNOLIA_AUTH_FRONTEND_PASSWORD) {
    return axios(url);
  }
  return axios(url, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
}
export function apiFetch(url: string): ReturnType<typeof fetch> {
  if (!process.env.MAGNOLIA_AUTH_FRONTEND_USER || !process.env.MAGNOLIA_AUTH_FRONTEND_PASSWORD) {
    return fetch(url);
  }
  return fetch(url, {
    headers: {
      Authorization: `Basic ${credentials}`,
    },
  });
}
export const rootNodePath: string = process.env.MAGNOLIA_SITE_PATH ?? '';

interface errorMsg {
  code: string;
  message: string;
}

export interface MagnoliaNode {
  '@name': string;
  '@path': string;
  '@id': string;
  '@nodeType': string;
  '@nodes': string[];
  '@index'?: number;
}
// { [key: string]: string }
export interface MagnoliaPageNode extends MagnoliaNode {
  'mgnl:created': string;
  'mgnl:lastModified': string;
  'mgnl:template'?: string;
  error?: errorMsg;
}

export interface MagnoliaAreaNode extends MagnoliaNode {
  'mgnl:created': string;
  'mgnl:lastModified': string;
}

export interface MagnoliaComponentNode extends MagnoliaPageNode {}

export interface MagnoliaDamNode extends MagnoliaNode {
  '@link': string; // TODO: maybe move this to MagnoliaNode
  metadata: {
    fileName: string;
    fileSize: number;
    format: string;
    mimeType: string;
    width: number;
    height: number;
    title?: string;
    caption?: string;
  };
  renditions?: {
    [rendition: string]: {
      mimeType: string;
      link: string;
    };
  };
}

export type LinkSwitchableMixin = {
  // TODO: find a better way for switchable fields
  linkType: 'page' | 'external' | 'download';
  linkTypepage?: {
    '@name': string;
    '@path': string;
    '@id': string;
    '@link': string;
  } & MagnoliaDamNode;
  linkTypedownload?: {
    '@name': string;
    '@path': string;
    '@id': string;
    '@link': string;
  } & MagnoliaDamNode;
  linkTypeexternal?: string;
};

export type customImageSizeSwitchableMixin = {
  imageSize: 'original' | 'thumbnail' | 'small' | 'medium' | 'large' | 'square' | 'custom';
  // CamelCase is not working here due to the naming in CMS, same for LinkSwitchableMixin
  imageSizecustomImageWidth: number;
  imageSizecustomImageHeight: number;
};

export interface MagnoliaMappingConfig {
  layoutMappings: { [key: string]: React.FC<any> };
  componentMappings: { [key: string]: React.FC<any> };
}

export interface HeaderNavTypeChild extends MagnoliaNode {
  breadCrumbs?: boolean;
  hideInNav?: boolean;
  navigationTitle?: string;
  'mgnl:template'?: string;
}

export type HeaderNavType = { [a: string]: HeaderNavType } & HeaderNavTypeChild;

export const config: MagnoliaMappingConfig = {
  layoutMappings: {
    'website:pages/static': StaticLayout,
    'website:pages/static-raw': StaticLayout,
  },
  componentMappings: {
    'website:pages/static': StaticPage,
    'website:pages/static-raw': StaticRawPage,

    'website:components/container': Container,
    'website:components/row': Row,
    'website:components/col': Col,

    'website:components/wrapper': Wrapper,
    'website:components/headline': Headline,
    'website:components/richText': RichText,
    'website:components/image': Image,
    'website:components/footer': Footer,
    'website:components/header': Header,
    'website:components/accordion': Accordion,
    'website:components/accordion-element': AccordionElement,
    'website:components/fixedColumnLayout': FixedColumnLayout,
    'website:components/navigation': Navigation,
    'website:components/teaser': Teaser,
    'website:components/link': Link,
    'website:components/linklist': LinkList,
    // 'website:components/poster': Poster,
    'website:components/button': Button,
    // 'website:components/quotes': Quotes,
    'website:components/consentButton': ConsentButton,
    // 'website:components/form': Form,
    // 'website:components/form-field-text': Textfield,
    // 'website:components/form-field-textarea': Textarea,
    // 'website:components/form-field-file': FileInput,
    // 'website:components/form-field-select': Select,
    // 'website:components/form-recaptcha': ReCaptcha,
    // 'website:components/form-field-checkbox': CheckBox,
    // 'website:components/form-button': FormButton,
  },
};

export interface Props {
  magnoliaContext: IMagnoliaContext;
  rootNodePath: string;
  page: StaticPageDefaultLayoutProps;
  headerNav: HeaderNavType[];
  templateAnnotations?: Object;
  config: MGNLConfigType;
  breadCrumb: CMSApiBreadCrumbResponse['nodes'];
  magnoliaUrl: string;
}

// export type MGNLConfigType = MagnoliaNode & Partial<ConfigModel>;
export type MGNLConfigType = Partial<ConfigModel>;
export type StaticPageLayoutProps = StaticPageDefaultLayoutProps;
