import { EditableArea } from '@magnolia/react-editor';
import { Props } from '../Layouts/DefaultLayout';
import { MagnoliaAreaNode } from '../../Utils/magnolia';

export interface StaticPageProps extends Props {
  header: MagnoliaAreaNode;
  footer: MagnoliaAreaNode;
  body: MagnoliaAreaNode;
}

const StaticPage = (props: StaticPageProps) => {
  const { header, footer, body } = props;
  return (
    <>
      {header && <EditableArea content={header} />}
      {body && <EditableArea content={body} />}
      {footer && <EditableArea content={footer} />}
    </>
  );
};

export default StaticPage;
