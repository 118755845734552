import { useEffect, useState, useMemo, AnchorHTMLAttributes } from 'react';
// import { useMedia } from 'react-use';

import { LinkSwitchableMixin, MagnoliaAreaNode, MagnoliaDamNode } from '@/src/Utils/magnolia';
import { ButtonPropsInterface, getButtonAttr } from '../button/Button';
import getLinkAttr from '../linkList/useLinkAttr';
import { ButtonProps } from 'react-bootstrap';
import { useMGNLGlobalPropsContext } from '@/src/Utils/MgnlGlobalContext';

export const HEADER_ID = 'header_id';
type HeaderHead = LinkSwitchableMixin & ButtonPropsInterface;

export interface HeaderProps extends HeaderHead {
  image: LinkSwitchableMixin['linkTypedownload'];
  imageSmall: LinkSwitchableMixin['linkTypedownload'];
  links: MagnoliaAreaNode & {
    [key: string]: LinkSwitchableMixin & { openLinkInNewBrowserTab?: boolean; title: string };
  };
  imageTitle: string;
  imageAltText: string;
}

interface ReturnTypeImage {
  url: string;
  metadata?: MagnoliaDamNode['metadata'];
  renditions?: MagnoliaDamNode['renditions'];
}

export interface HeaderReturnProps {
  isSticky: boolean;
  // isWide: boolean;
  links: LinkListType[];
  imageTitle: string;
  imageAltText: string;
  image: {
    mobile: ReturnTypeImage;
    bigScreen: ReturnTypeImage;
  };
  button: {
    attr: ButtonProps;
    buttonLinkAttr: AnchorHTMLAttributes<any>;
    title: string;
  };
}

export type LinkListType = { attr: AnchorHTMLAttributes<any>; text: string };

const useHeader = (props: HeaderProps): HeaderReturnProps => {
  // const mediaIsWidee = useMedia('(min-width: 480px)');
  const { links, ctaName, imageTitle, imageAltText, image, imageSmall } = props;
  // const [isWide, setIsWide] = useState(mediaIsWidee);
  const [isSticky, setIsSticky] = useState(false);
  const mgnlCtx = useMGNLGlobalPropsContext();

  const linksList = useMemo(() => {
    const arr: LinkListType[] = [];
    links['@nodes'].map(key => {
      const node = links[key];
      const [attr] = getLinkAttr(node);
      arr.push({ attr, text: node.title });
    });
    return arr;
  }, [links]);
  console.log('====================================');
  console.log('props props ', props);
  console.log('====================================');
  const button = useMemo(() => {
    return {
      attr: getButtonAttr(props)[0],
      buttonLinkAttr: getLinkAttr(props)[0],
      title: ctaName,
    };
  }, [props, ctaName]);

  // useEffect(() => {
  //   setIsWide(mediaIsWidee);
  // }, [mediaIsWidee]);

  useEffect(() => {
    const handleScroll = () => {
      const headerElt = document.getElementById(HEADER_ID);
      if (headerElt) {
        const topOffset = headerElt.offsetTop;
        if (window.pageYOffset > topOffset) {
          if (!isSticky) setIsSticky(true);
        } else {
          if (isSticky) setIsSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky]);

  return {
    isSticky,
    // isWide,
    links: linksList,
    button,
    imageAltText,
    imageTitle,
    image: {
      // small: (mgnlCtx?.magnoliaUrl || '') + imageSmall?.['@link'] || '',
      // bigScreen: (mgnlCtx?.magnoliaUrl || '') + image?.['@link'] || '',
      mobile: {
        url: imageSmall?.['@link'] || '',
        metadata: imageSmall?.metadata,
        renditions: imageSmall?.renditions,
      },

      bigScreen: {
        url: image?.['@link'] || '',
        metadata: image?.metadata,
        renditions: image?.renditions,
      },
    },
  };
};

export default useHeader;
