import { LinkSwitchableMixin } from '@/src/Utils/magnolia';
import { CSSProperties } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import getLinkAttr from '../linkList/useLinkAttr';
import Link from 'next/link';
import formatStyle from '@/src/Utils/formatStyle';

export interface ButtonPropsInterface {
  ctaName: string;
  additionalClass: string;
  isFullWidth: boolean;
  buttonType: 'primary' | 'secondary' | 'thirdly' | 'fourthly';
  size: 'sm' | 'lg' | 'xl' | 'xxl';
  styles: string;
}

const ButtonWrapper = (props: ButtonPropsInterface & LinkSwitchableMixin) => {
  const [attr] = getLinkAttr(props);
  return (
    <Link href={'/'} {...attr}>
      <ButtonSample {...props} />
    </Link>
  );
};

export default ButtonWrapper;

export const getButtonAttr = (props: ButtonPropsInterface): [ButtonProps] => {
  let attr: ButtonProps = { className: 'btn' };
  const { additionalClass, buttonType, isFullWidth, styles, size } = props;
  if (additionalClass) attr = { ...attr, className: `${attr.className || ''} ${additionalClass}` };
  if (buttonType) attr = { ...attr, className: `${attr.className || ''} btn-${buttonType}` };
  if (isFullWidth) attr = { ...attr, className: `${attr.className || ''} w-100` };
  if (styles) attr = { ...attr, style: formatStyle(styles) };
  if (size) attr = { ...attr, className: `${attr.className || ''} btn-${size}` };

  return [attr];
};

export const ButtonSample = (props: ButtonPropsInterface & { onClick?: () => void }) => {
  const { ctaName, onClick } = props;
  const [buttonAttr] = getButtonAttr(props);
  return (
    <button onClick={onClick} {...buttonAttr}>
      {ctaName}
    </button>
  );
};
