import { Container } from 'react-bootstrap';
import { EditableArea } from '@magnolia/react-editor';
import { MagnoliaAreaNode, MagnoliaPageNode } from '@/src/Utils/magnolia';

interface ContainerProps {
  container: MagnoliaAreaNode;
  metadata: MagnoliaPageNode;
  isFluid: boolean;
}

const ContainerWrapper = (props: ContainerProps) => {
  const { container, isFluid } = props;
  return <Container fluid={isFluid}>{container && <EditableArea content={container} />}</Container>;
};
export default ContainerWrapper;
