import HeaderMobile from './HeaderMobile';
import HeaderBigScreen from './HeaderBigScreen';
import useHeader, { HeaderProps } from './useHeader';

const Header = (props: HeaderProps) => {
  const res = useHeader(props);

  return (
    <>
      <div className="d-none d-sm-block">
        <HeaderBigScreen {...res} />
      </div>
      <div className="d-block d-sm-none">
        <HeaderMobile {...res} />
      </div>
    </>
  );
};

export default Header;
