import { createContext, useContext } from 'react';
import createTheme from '../lib/Styles/theme';
import projectTheme from '../Styles/theme';
import CustomTheme from '../lib/Styles/Theme/CustomTheme';

const theme = createTheme(projectTheme);
export const ThemeContext = createContext<CustomTheme>(theme);

export const useTheme = (): CustomTheme => {
  return useContext(ThemeContext);
};
