import createTheme from '../lib/Styles/theme';

const projectTheme = createTheme({
  bootstrap: {
    borderWidth: '2px',
    dark: '#000000',
    black: '#333333',
    gray100: '#aaa',
    gray200: '#bbb',
    gray300: '#ccc',
    gray400: '#ddd',
    gray500: '#eee',
    gray600: '#f1f1f1',
    gray700: '#f2f2f2',
    gray800: '#f3f3f3',
    gray900: '#f4f4f4',
    inputBorderColor: '#eee',
    inputBorderRadius: '6px',
    inputColor: '#333333',
    inputErrorColor: '#ff385c',
    inputFocusBoxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    inputFontSize: '1rem',
    inputFontWeight: '500',
    // primary: '#fed446',
    primary: '#ffcd00',
    secondary: '#00d664',
    spacer: '5px',
    white: '#eee',
  },
});
export default projectTheme;
